import { useTranslation } from "react-i18next";
import Button from "../../../../assets/TakyonDesignSystem/components/Button/Button";
import { RegisterBankAccountPage } from "../../../../assets/TakyonDesignSystem/components/Wallet/sub/WalletBalance";
import { openModal } from "../../../../assets/Utils/modal";
import {
  iStripeAccountData,
  iStripeBankAccount,
} from "../../../../assets/models/iStripe";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux/slices/userSlice";
import StripeController from "../../../../assets/Controllers/StripeController";
import { apiErrorToast } from "../../../../assets/Utils/errors";
import UserController from "../../../../assets/Controllers/UserController";
import { fireTagManagerEvent } from "../../../../assets/Services/tagmanager";
import RemoveBankAccount from "./RemoveBankAccount";
import helpers from "../../../helpers/helpers";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../../assets/Utils/generic";

export default function Earnings() {
  const { t } = useTranslation();

  const [account, setAccount] = useState<iStripeAccountData>();
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectUser)!;

  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const loadStripeAccount = async () => {
    setIsLoading(true);
    try {
      const account = await StripeController.getStripeAccountData();
      setAccount(account);
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadStripeAccount();
  }, [user]);

  const setDefault = async (id: string) => {
    setIsLoading(true);
    try {
      await StripeController.setDefaultForCurrency({ bankAccountId: id });
      await UserController.initLoggedUser();

      fireTagManagerEvent("default_bank_account");
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  const needToConfigureStripe =
    (!user?.stripeDetailsSubmitted || !user?.stripeChargesEnabled) &&
    !account?.stripeAccount;

  const isBeingVerified =
    user?.stripeDetailsSubmitted &&
    account?.stripeAccount?.individual.requirements?.pending_verification
      .length !== 0;

  const isStatusUnverified =
    (!user?.stripeDetailsSubmitted || !user?.stripeChargesEnabled) &&
    account?.stripeAccount?.individual?.verification?.status === "unverified";

  const isVerified = user?.stripeDetailsSubmitted && user?.stripeChargesEnabled;

  return (
    <div id="earnings">
      <div>
        <span className="h4 medium">
          {t("profile_transactions.earnings.regbankaccount")}
        </span>
        <div className="d-flex">
          <div className="w-100 bodytext light">
            {t("profile_transactions.earnings.regbankaccounttext")}
          </div>
          <div>
            <Button
              onClick={() => {
                openModal({
                  content: <RegisterBankAccountPage />,
                });
              }}
              text={t("profile_transactions.earnings.regbackaccountaction")}
            />
          </div>
        </div>
      </div>

      <div className="height-24"></div>

      <div className="d-flex gap-3 flex-wrap">
        {account?.bankAccountList?.map((bankAccount: iStripeBankAccount) => {
          return (
            <div
              key={"bankAccount_" + Math.random()}
              style={{ padding: "24px", width: isSmallScreen ? "100%" : "45%" }}
              className="d-flex justify-content-between align-items-end border rounded"
            >
              <div>
                <p className="m-0 bodytext medium">
                  {bankAccount.account_holder_name}
                </p>
                <div className="height-7"></div>
                <p className="m-0 bodytext regular">****{bankAccount.last4}</p>
                <div className="height-7"></div>
                <p className="m-0 bodytext regular dark-grey-color">
                  {bankAccount.country} / {bankAccount.currency}
                </p>
              </div>

              {bankAccount.default_for_currency ? (
                <div>
                  <p className="m-0 bodytext-xs light-black-color">
                    {t("wallet.earnings.default")}
                  </p>
                </div>
              ) : null}

              {!bankAccount.default_for_currency ? (
                <div className="d-flex flex-column gap-2">
                  <Button
                    loading={isLoading}
                    size="sm"
                    className="w100"
                    onClick={() => setDefault(bankAccount.id)}
                    text={t("wallet.earnings.setdefault")}
                  />
                  <Button
                    loading={isLoading}
                    size="sm"
                    className="w100"
                    onClick={() =>
                      openModal({
                        content: (
                          <RemoveBankAccount bankAccount={bankAccount} />
                        ),
                      })
                    }
                    // onClick={() => deleteAccount(bankAccount.id)}
                    variant="secondary"
                    text={t("wallet.earnings.deleteaccount")}
                  />
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
      <div className="horizontal-divider" style={{ margin: "24px 0" }}></div>
      <div>
        {isBeingVerified ||
        (!needToConfigureStripe && !isStatusUnverified && !isVerified) ? (
          <>
            <p className="m-0 bodytext medium">
              {t("wallet.earnings.verifying")}
            </p>
            <div className="height-4"></div>
            <p className="m-0 bodytext regular dark-grey-color">
              {t("wallet.earnings.verifyingtext")}
            </p>
          </>
        ) : null}
        {isStatusUnverified ? (
          <>
            <p className="m-0 bodytext medium">
              {t("wallet.earnings.verifyingerror")}
            </p>
            <div className="height-4"></div>
            <p className="m-0 bodytext regular dark-grey-color">
              {t("wallet.earnings.verifyingerrortext1")}{" "}
              <a href="mailto:support@takyon.io">
                <u>support@takyon.io</u>
              </a>{" "}
              {t("wallet.earnings.verifyingerrortext2")}
            </p>

            <ul>
              {account.stripeAccount.individual.requirements.errors.map(
                (error: any, index) => (
                  <li className="font-color" key={index}>
                    Error: {error.reason}
                  </li>
                )
              )}
            </ul>
            <ul>
              {account.stripeAccount.individual.requirements.currently_due.map(
                (error: any, index) => (
                  <li className="font-color" key={index}>
                    Currently due: {error}
                  </li>
                )
              )}
            </ul>
          </>
        ) : null}
        {isVerified ? (
          <>
            <div
              key={"bankAccount_" + Math.random()}
              style={{ padding: "24px", margin: "6px 0" }}
              className="d-flex justify-content-between align-items-end border rounded"
            >
              <div>Saldo:</div>
              <div className="m-0 h3 medium">
                {helpers.price(Number(account?.balance))}
              </div>
            </div>

            <p className="m-0 bodytext light dark-grey-color">
              {t("wallet.earnings.earningstext")}
            </p>
          </>
        ) : null}
      </div>
    </div>
  );
}
