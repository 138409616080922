import { useTranslation } from "react-i18next";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";

import {
  iWallSlot,
  WallSlotTypePayload,
  WallSlotPromo,
} from "../../../../config/wall";
import Icon from "../Icon/Icon";

interface Props {
  slot?: iWallSlot;
}

export default function PromoContainer(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (props.slot?.config?.type) {
    const promoConfig = props.slot?.config as WallSlotTypePayload;
    const payload = promoConfig.payload as WallSlotPromo;

    return (
      <div className="text-white mt-5 mb-5">
        <h2 className="h1 regular m-0">{t(props.slot?.title ?? "")}</h2>
        {/* <p>{t(props.slot?.subtitle ?? "")}</p> */}
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "32px" }}
        >
          <div className="d-inline-flex flex-wrap"></div>
        </div>
        <div className="panel w-full h-small bodytext small medium">
          <div
            className="panel-bg"
            style={{ backgroundImage: `url(${payload?.background ?? ""})` }}
          ></div>
          <div className="panel-shadow"></div>
          <div className="panel-header">
            <div className="tag tag-black bodytext regular mb-2">
              <Icon
                icon="brightness_alert"
                fill
                style={{ fill: "white" }}
              ></Icon>{" "}
              New
            </div>
          </div>
          <div className="panel-bottom">
            <div className="panel-body">
              <div className="col-12 col-md-8">
                <div>
                  <p className="h2 regular">{t(payload?.title) ?? ""}</p>
                  <p className="bodytext regular">
                    {t(payload?.subtitle) ?? ""}
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <Button
                  className="w-100"
                  text={t("wall.promo.explore")}
                  onClick={() => navigate(payload.action ?? "")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
