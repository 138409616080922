import { useMediaQuery } from "react-responsive";
import { iWallConfig, iWallSlot, WALL_SLOT_TYPE } from "../../../config/wall";
import TakContainer from "../../../assets/TakyonDesignSystem/components/TakContainer/TakContainer";
import LastMinute from "../../../assets/TakyonDesignSystem/components/LastMinute/LastMinute";
import Promo from "../../../assets/TakyonDesignSystem/components/Promo/Promo";
import Partner from "../../../assets/TakyonDesignSystem/components/Partner/PartnerContainer";
import Offer from "../../../assets/TakyonDesignSystem/components/Offer/Offer";
import StayRequest from "../../../assets/TakyonDesignSystem/components/StayRequest/StayRequest";
import SocialRequest from "../../../assets/TakyonDesignSystem/components/SocialRequest/SocialRequest";

export default function Wall(props: {
  menuCategories: iWallConfig;
  category: iWallSlot[];
}) {
  const isShortScreen = useMediaQuery({
    query: "only screen and (min-width : 769px) and (max-height : 600px)",
  });

  return (
    <main style={{ marginTop: isShortScreen ? "44px" : "55px" }}>
      <section className="w-100 black-background position-relative">
        {/* top menu */}

        {props.category ? (
          <div
            className="container d-flex flex-column"
            style={{ paddingTop: isShortScreen ? "20px" : "50px" }}
          >
            {props.category.map((slot: iWallSlot, key) => {
              switch (slot.config.type) {
                case WALL_SLOT_TYPE.CLASSIC:
                  return <TakContainer key={`tak_${key}`} slot={slot} />;
                case WALL_SLOT_TYPE.LAST_MINUTE:
                  return (
                    <LastMinute key={`last_${key}`} slot={slot}></LastMinute>
                  );
                case WALL_SLOT_TYPE.PROMO:
                  return <Promo key={`promo_${key}`} slot={slot}></Promo>;
                case WALL_SLOT_TYPE.PARTNER:
                  return <Partner key={`partner_${key}`} slot={slot}></Partner>;
                case WALL_SLOT_TYPE.OFFER:
                  return <Offer key={`offer_${key}`} slot={slot}></Offer>;
                case WALL_SLOT_TYPE.STAY_REQUEST:
                  return <StayRequest key={`offer_${key}`} slot={slot} />;
                case WALL_SLOT_TYPE.SOCIAL:
                  return <SocialRequest key={`offer_${key}`} slot={slot} />;
              }
            })}
          </div>
        ) : null}
      </section>
    </main>
  );
}
